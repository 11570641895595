import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For most integrations we recommend you use the iFrame as implementing your own session management for
the end user quickly becomes a more complicated than you'd first expect when you consider the mobile
ecosystem and session interruption.`}</p>
    <h1 {...{
      "id": "quickstart-guide",
      "style": {
        "position": "relative"
      }
    }}>{`Quickstart Guide`}<a parentName="h1" {...{
        "href": "#quickstart-guide",
        "aria-label": "quickstart guide permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h1>
    <p>{`This API quickstart guide is here to help you get up and running with TaleFin's API. In this guide, we
will be connecting a user with their nominated bank account, extracting and analysing all of the
transactions from the last year in that account and displaying it; all by using the API.`}</p>
    <h2 {...{
      "id": "access",
      "style": {
        "position": "relative"
      }
    }}>{`Access`}<a parentName="h2" {...{
        "href": "#access",
        "aria-label": "access permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`To gain access, you must first create an account by using the demo (top right hand corner of this site).
All the information you need will be sent to you in an email. Once that is done, you can use the iFrame
and access the dashboard to view the analyses.`}</p>
    <p>{`You will be provided with a vendor label; basically a small identifier that lets us know the application
belongs to you. This label will be used in some API requests as a way of quickly and positively identifying
your organisation.`}</p>
    <h2 {...{
      "id": "basic-user-flow",
      "style": {
        "position": "relative"
      }
    }}>{`Basic User Flow`}<a parentName="h2" {...{
        "href": "#basic-user-flow",
        "aria-label": "basic user flow permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`There are three simple steps required to connect an end-user and analyse their transaction information.`}</p>
    <ul>
      <li parentName="ul">{`Submit the initial user information`}</li>
      <li parentName="ul">{`Handle any inputs that the bank requests, as necessary, including Multi-Factor Authentication (MFA)`}</li>
      <li parentName="ul">{`Retrieve data once the webhooks announce it's ready`}</li>
    </ul>
    <p>{`We'll cover each of these steps in greater detail below.`}</p>
    <h2 {...{
      "id": "submit-the-initial-user-information",
      "style": {
        "position": "relative"
      }
    }}>{`Submit the Initial User Information`}<a parentName="h2" {...{
        "href": "#submit-the-initial-user-information",
        "aria-label": "submit the initial user information permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Submitting the user information creates what is referred
to as a profile of that end user. A profile contains all
the relevant information for that user. To create a profile
POST the appropriate information to the following endpoint:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/v1/{vendor_label}/{vendor_specific_id}/profile`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`This endpoint accepts the following information:`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "full_name": "string",
  "email": "string",
  "phone": "string",
  "bank_id": int,
  "days_to_retrieve": int,
  "balances": boolean,
  "transactions": boolean,
  "interims": boolean,
  "estatements": boolean,
  "analysis": boolean,
  "account_owner": boolean
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You will notice one of the requirements as a `}<inlineCode parentName="p">{`vendor_specific_id`}</inlineCode>{`.
This is a unique code created by yourself, that you should store
somewhere safe. It is your reference number that you can be used
to find your users' analysed transaction information in the future.`}</p>
    <p>{`There are a whole bunch of paramaters that you can send to this API
endpoint, however, to keep it simple let’s just send a few and rely
on the defaults:`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "full_name": "Donald Duck",
  "email": "test@test.com",
  "phone": 040000000,
  "bank_id": 130,
  "analysis": true
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You can replace `}<inlineCode parentName="p">{`"analysis": true,`}</inlineCode>{` with any option from above, or even extend it to include several different options. For example;`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "full_name": " Donald Duck",
  "email": "test@test.com",
  "phone": 040000000,
  "bank_id": 130,
  "analysis": true,
  "account_owner": true
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`For the `}<inlineCode parentName="p">{`bank_id`}</inlineCode>{`, you can get a list of the most up to date supported banks from this endpoint:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/v1/banks`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In this case the test bank id, ‘Bank of TaleFin’, has been provided to run this initial test.
Upon sending the POST request a UUID will be returned, looking something like this:`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "profile": 10000,
  "uuid": "ba8f4a5e-be0b-4bcd-954a-483d86628e90",
  "application": 100000,
  "bank_id": 130,
  "timestamp": "2020-09-10T11:26:25.576759+10:00",
  "status": "QUEUED"
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Using this UUID you can now poll to collect updates and inputs required by
the bank crawler:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/v1/{vendor_label}/{vendor_specific_id}/io/{uuid}`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "handle-inputs",
      "style": {
        "position": "relative"
      }
    }}>{`Handle Inputs`}<a parentName="h2" {...{
        "href": "#handle-inputs",
        "aria-label": "handle inputs permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`This endpoint will tell you what you need to provide for the specific bank. For example,
`}<inlineCode parentName="p">{`username`}</inlineCode>{`, `}<inlineCode parentName="p">{`password`}</inlineCode>{`, `}<inlineCode parentName="p">{`mfa`}</inlineCode>{`. Using this information you can then POST back the response to
the same input to provide this for the bank crawler extracting the information from
the bank account.`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "status": "AUTH",
  "code": 1008,
  "message": {
    "status": "AUTH",
    "standardError": {
      "code": 1008,
      "message": "Requesting users initial credential inputs"
    },
    "inputs": {
      "pan": {
        "name": "pan",
        "label": "Personal Access Number",
        "rel": "user",
        "required": true,
        "attrs": {
          "autocomplete": "invalid-browser-string",
          "type": "text"
        },
        "decorations": []
      },
      "securecode": {
        "name": "securecode",
        "label": "Secure Code",
        "rel": "password",
        "required": true,
        "attrs": {
          "autocomplete": "invalid-browser-string",
          "type": "password"
        },
        "decorations": []
      }
    }
  },
  "pending_messages": [
    {
      "sequence": 6,
      "message": {
        "types": ["client", "input"],
        "payload": {
          "inputs": {
            "pan": {
              "name": "pan",
              "label": "Personal Access Number",
              "rel": "user",
              "required": true,
              "attrs": {
                "autocomplete": "invalid-browser-string",
                "type": "text"
              },
              "decorations": []
            },
            "securecode": {
              "name": "securecode",
              "label": "Secure Code",
              "rel": "password",
              "required": true,
              "attrs": {
                "autocomplete": "invalid-browser-string",
                "type": "password"
              },
              "decorations": []
            }
          }
        },
        "sequence": 6
      }
    }
  ],
  "overflow": null,
  "webhook_events": []
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`As you can see in this response, two inputs are
required `}<inlineCode parentName="p">{`pan`}</inlineCode>{` and `}<inlineCode parentName="p">{`securecode`}</inlineCode>{`. POSTing
these inputs with the provided key names e.g.`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{"pan": "username", "securecode": "password"}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The crawler is able to log into the account
and begin collecting the user information.
This is the same for any MFA requests that
are made by the bank account. Once the crawler
has progressed to a point that it will no longer
be needing to request any more input from you,
the status will change to ‘RELEASED’ (see
lifecycle documentation for more details).`}</p>
    <h2 {...{
      "id": "retrieve-the-data",
      "style": {
        "position": "relative"
      }
    }}>{`Retrieve the data`}<a parentName="h2" {...{
        "href": "#retrieve-the-data",
        "aria-label": "retrieve the data permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Using the Dashboard you can add a webhook to
`}<a parentName="p" {...{
        "href": "https://webhook.site"
      }}>{`webhook.site`}</a>{` for testing. We recommend
using Bank of TaleFin for testing as it is secure and allows
a number of errors and successes to be generated.
You can change or remove webhooks at any time either through
the API or in the dashboard.`}</p>
    <p>{`These webhooks will notify you when each component
for the account has been processed and is ready for collection.
To make it faster and more responsive, analyses are done
in such a way that we send the data in segments as it’s
ready for you to collect. However, for simplicity’s sake, we
shall wait until all analyses are completed. When it’s done
you will receive a webhook event called application.report_ready:`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "event": "application.report_ready",
  "payload": {
    "id": 100000,
    "vendor_specific_id": "123456789",
    "vendor_label": "test",
    "timestamp": "2020-09-10T08:53:02.229592+10:00",
    "crawler": [
      {
        "profile": 10000,
        "uuid": "ba8f4a5e-be0b-4bcd-954a-483d86628e90",
        "application": 100000,
        "bank_id": 130,
        "timestamp": "2020-09-10T08:53:04.039916+10:00",
        "status": "COMPLETED"
      }
    ],
    "full_name": "Donald Duck",
    "email": "test@test.com",
    "mobile": "04000000",
    "finalised": true,
    "analysed": true
  }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Using this information, we can view the fruits of our
labour, in HTML:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/v1/applications/{application_id}/summary/html`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`in JSON:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/v1/applications/{application_id}/summary`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`or PDF:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/v1/applications/{application_id}/summary/pdf`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "error-handling",
      "style": {
        "position": "relative"
      }
    }}>{`Error Handling`}<a parentName="h2" {...{
        "href": "#error-handling",
        "aria-label": "error handling permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You can view an exhaustive list of all the possible errors and warnings that could occur
by visiting the `}<a parentName="p" {...{
        "href": "/docs/v1/error-handling"
      }}>{`error docs`}</a>{`.`}</p>
    <h2 {...{
      "id": "analyse-existing-files",
      "style": {
        "position": "relative"
      }
    }}>{`Analyse existing files`}<a parentName="h2" {...{
        "href": "#analyse-existing-files",
        "aria-label": "analyse existing files permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`If you have account files from an external source that you wish to run through the Talefin analysis engine, then you can use the updated classify endpoint at:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/v1/{vendor_label}/{vendor_specific_id}/classify/append`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`This endpoint has been simplified for the user, so now all that is required is your JSON payload, the origin of that payload and a bank ID.
The default payload looks like this:`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "accounts": null,
  "bank": null,
  "analyse_now": false,
  "detect_dishonours": false,
  "days_to_retrieve": null,
  "days_to_display": null,
  "adjust_time_to_today": false,
  "origin": ""
}`}</code>{`
        `}</deckgo-highlight-code>
    <p><strong parentName="p">{`accounts`}</strong>{` required`}</p>
    <p>{`This is where you put your existing file, which should be a JSON packet containing the bank accounts and transactions belonging to a single bank profile.`}</p>
    <p>{`For Plaid users, you will need to append the `}<inlineCode parentName="p">{`numbers`}</inlineCode>{` values from their `}<inlineCode parentName="p">{`/auth`}</inlineCode>{` endpoint to your asset file as these are not included by default.`}</p>
    <p><strong parentName="p">{`bank`}</strong>{` required`}</p>
    <p>{`This is the bank ID which you can find on the `}<inlineCode parentName="p">{`/banks`}</inlineCode>{` endpoint above.`}</p>
    <p><strong parentName="p">{`analyse_now`}</strong>{` optional`}</p>
    <p>{`This determines when to run the analysis. Set this to `}<inlineCode parentName="p">{`true`}</inlineCode>{` for single profile applications or to finalise a multiple profile application.`}</p>
    <p><strong parentName="p">{`detect_dishonours`}</strong>{` optional`}</p>
    <p>{`This determines whether to include the enhanced dishonour matching feature in the analysis.`}</p>
    <p><strong parentName="p">{`days_to_retrieve`}</strong>{` optional`}</p>
    <p>{`Set this to the date range of transactions you would like the analysis to run on. This would typically be the same as the date range of the data in your `}<inlineCode parentName="p">{`accounts`}</inlineCode>{` payload. If no value is provided, it will default to your vendor label's `}<inlineCode parentName="p">{`days_to_retrieve`}</inlineCode>{` setting.`}</p>
    <p><strong parentName="p">{`days_to_display`}</strong>{` optional`}</p>
    <p>{`Set this to the date range of transactions you would like displayed as a raw statement in the analysis. This can be used to truncate the data in the analysis without affecting the results. If no value is provided, it will default to your vendor label's `}<inlineCode parentName="p">{`days_to_display`}</inlineCode>{` setting.`}</p>
    <p><strong parentName="p">{`adjust_time_to_today`}</strong>{` optional`}</p>
    <p>{`Set this to `}<inlineCode parentName="p">{`true`}</inlineCode>{` to shift the date range of transactions such that the most recent occur on the current day. This can be used to analyse payloads containing data older than 365 days.`}</p>
    <p><strong parentName="p">{`origin`}</strong>{` required`}</p>
    <p>{`This determines where the provided payload comes from. Currently supported payloads include:`}</p>
    <p><inlineCode parentName="p">{`"illion"`}</inlineCode>{` (Illion Report); `}</p>
    <p><inlineCode parentName="p">{`"creditsense"`}</inlineCode>{` (Credit Sense Data Only Report); `}</p>
    <p><inlineCode parentName="p">{`"creditsense-detailed"`}</inlineCode>{` (Credit Sense Detailed Report); `}</p>
    <p><inlineCode parentName="p">{`"flinks"`}</inlineCode>{` (Canadian Bank Report);`}</p>
    <p><inlineCode parentName="p">{`"plaid"`}</inlineCode>{` (Canadian Bank Report);`}</p>
    <p><inlineCode parentName="p">{`"talefin"`}</inlineCode>{` (Talefin bundle JSON).`}</p>
    <p>{`After a successful POST, you will receive a response that will look something like this:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "application_id": 123456,
  "profile_id": 98765,
  "vendor_label": "{vendor_label}",
  "vendor_specific_id": "{vendor_specific_id}"
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You can then view your application running at:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/dashboard/lookup?vendor_specific_id={vendor_specific_id}`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "recategorise-transactions-for-reanalysis",
      "style": {
        "position": "relative"
      }
    }}>{`Recategorise transactions for reanalysis`}<a parentName="h2" {...{
        "href": "#recategorise-transactions-for-reanalysis",
        "aria-label": "recategorise transactions for reanalysis permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`If you believe a transaction to be miscategorised you can modify it and perform reanalysis using the following endpoint:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/v1/{vendor_label}/{vendor_specific_id}/classify/recategorise`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`To use this endpoint, you need to have a completed (i.e. analysed and finalised) application.
The `}<inlineCode parentName="p">{`vendor_label`}</inlineCode>{` and `}<inlineCode parentName="p">{`vendor_specific_id`}</inlineCode>{` in the request URL should correspond to that of the completed application containing the transactions you wish to modify.`}</p>
    <p>{`The default payload looks like this:`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "transaction_info_category_updates": 
  {
    "updates": [
        {
          "transaction_info_id": int,
          "category_id": int
        }
    ]
  },
  "analyse_now": false
}`}</code>{`
        `}</deckgo-highlight-code>
    <p><strong parentName="p">{`transaction_info_category_updates`}</strong>{` required`}</p>
    <p>{`This should be a JSON payload containing an array of the updates you wish to make.
An update should be a dict of `}<inlineCode parentName="p">{`transaction_info_id`}</inlineCode>{` and `}<inlineCode parentName="p">{`category_id`}</inlineCode>{`.
The endpoint will return with a status of 400 if no updates are provided, including an empty array.`}</p>
    <p><strong parentName="p">{`transaction_info_id`}</strong>{` required`}</p>
    <p>{`The ID of the transaction info object you want to make an update to. This can be found in the Talefin account analysis, application report and application bundle JSON files.
This can come from any account on any profile within the application matching the `}<inlineCode parentName="p">{`vendor_label`}</inlineCode>{` and `}<inlineCode parentName="p">{`vendor_specific_id`}</inlineCode>{` provided in the request URL.`}</p>
    <p><strong parentName="p">{`category_id`}</strong>{` required`}</p>
    <p>{`The ID of the new category you wish to update the transaction to. A list of valid categories can be found on the following endpoint:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/v1/categories`}</code>{`
        `}</deckgo-highlight-code>
    <p><strong parentName="p">{`analyse_now`}</strong>{` optional`}</p>
    <p>{`This determines whether to run reanalysis, after which any updates made will be visible in the application's analysis documents.
This means you can continue to hit the endpoint with `}<inlineCode parentName="p">{`"analyse_now": false`}</inlineCode>{` to keep making updates before reanalysing.
The default value is `}<inlineCode parentName="p">{`false`}</inlineCode>{`. `}</p>
    <p>{`After a successful POST, you will receive a response that will look something like this:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
    "application_id": 123456,
    "vendor_label": "{vendor_label}",
    "vendor_specific_id": "{vendor_specific_id}"
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You can then view your application running at:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/dashboard/lookup?vendor_specific_id={vendor_specific_id}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`After reanalysis is completed, you can observe the updates you made in the analysis and summary documentation.
Additionally, a log of any updates made will be created so that we may continue to improve our categorisation engine in the future.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      